<template>
    <el-container>
        <el-header class="header flex-center">
            <div class="logo"></div>
            <!-- <img class="logo" src="../assets/logo.png" alt="" /> -->
        </el-header>
        <el-container>
            <el-aside width="30%" class="middle mt-6 menu flex-end1">
                <div class="menu">
                    <div class="title">The questionnaire list</div>
                    <div class="step1 back">Step 1 – Business Information</div>
                </div>
            </el-aside>
            <el-main class="mt-6" width="70%">
                <div class="content">
                    <div class="name">Vendor</div>
                    <!-- Company -->
                    <div style="margin-top: 59px">
                        <!-- Company Information-->
                        <div class="inputList">
                            <div class="company">Company Information</div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Company Name
                                    <span class="red-text">*</span>
                                </div>
                                <div class="input">
                                    <el-input
                                        placeholder="Company Name"
                                        v-model="company.companyName"
                                        v-replace="company.companyName"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Business Address
                                    <span class="red-text">*</span>
                                </div>
                                <div class="select flex-start">
                                    <Address
                                        @change="
                                            changeAddress(
                                                $event,
                                                'Company',
                                                company
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal"></div>
                                <div class="textarea">
                                    <el-input
                                        type="textarea"
                                        :rows="3"
                                        placeholder=""
                                        v-model="company.businessAddressDetail"
                                    >
                                    </el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">Post Code</div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.postCode"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">Tel No.</div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.telNo"
                                        @change="changeTest($event, 'telNo')"
                                    >
                                    </el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">Website</div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.website"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Business License No.
                                    <span class="red-text">*</span>
                                </div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.businessLicenseNo"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Business Type
                                    <span class="red-text">*</span>
                                </div>
                                <div class="select">
                                    <el-select
                                        v-model="company.businessType"
                                        placeholder="Please select"
                                        style="width: 544px"
                                    >
                                        <el-option
                                            v-for="item in businessList"
                                            :key="item.dictName"
                                            :label="item.dictName"
                                            :value="item.dictName"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Date Established
                                    <span class="red-text">*</span>
                                </div>
                                <div class="select">
                                    <el-date-picker
                                        v-model="company.dateEstablished"
                                        type="date"
                                        format="dd/MM/yyyy"
                                        value-format="dd/MM/yyyy"
                                        placeholder="Please select a date"
                                        style="width: 100%"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Ownership Structure
                                    <span class="red-text">*</span>
                                </div>
                                <div class="select">
                                    <el-checkbox-group
                                        v-model="checkList1"
                                        class="mt-1"
                                        @change="
                                            changeCheckboxs($event, company)
                                        "
                                    >
                                        <el-checkbox
                                            :label="item.dictName"
                                            v-for="(item, i) in ownershipList"
                                            :key="i"
                                        >
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">Business Scale</div>
                                <div class="table">
                                    <div class="flex-center itemTable">
                                        <div
                                            v-for="(item, t) in yearNmae"
                                            :key="t"
                                        >
                                            {{ item }}
                                        </div>
                                    </div>
                                    <div
                                        class="flex-center itemTable"
                                        v-for="(
                                            item, i
                                        ) in company.businessScaleList"
                                        :key="i"
                                    >
                                        <div>{{ item.aliasName }}</div>
                                        <div>
                                            <input
                                                type="text"
                                                v-model="item.thisYear"
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                v-model="item.lastYear"
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                v-model="item.previousYear"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">Company Info</div>
                                <div class="textarea">
                                    <el-input
                                        type="textarea"
                                        :rows="3"
                                        placeholder="Please enter the content"
                                        v-model="company.companyInfo"
                                    >
                                    </el-input>
                                </div>
                            </div>
                        </div>
                        <!-- Key Contact -->
                        <div class="inputList">
                            <div class="company">Key Contact</div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Contact Person
                                    <span class="red-text">*</span>
                                </div>
                                <div class="select flex-start">
                                    <el-select
                                        v-model="company.contact.contactSex"
                                        placeholder="Please select"
                                    >
                                        <el-option
                                            v-for="item in sexList"
                                            :key="item.dictName"
                                            :label="item.dictCode"
                                            :value="item.dictName"
                                        >
                                        </el-option>
                                    </el-select>
                                    <div
                                        class="input"
                                        style="width: 350px; margin-left: 16px"
                                    >
                                        <el-input
                                            placeholder=""
                                            v-model="
                                                company.contact.contactName
                                            "
                                        ></el-input>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Position
                                    <span class="red-text">*</span>
                                </div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.contact.position"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Email Address
                                    <span class="red-text">*</span>
                                </div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.contact.emailAddress"
                                        @change="changeTest($event, 'email')"
                                    ></el-input>
                                </div>
                            </div>
                            <div class="flex-between mb-2">
                                <div class="text-normal">
                                    Mobile Number
                                    <span class="red-text">*</span>
                                </div>
                                <div class="input">
                                    <el-input
                                        placeholder=""
                                        v-model="company.contact.mobileNumber"
                                        @change="changeTest($event, 'telNo')"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                        <!--Attachment  -->
                        <Attachments
                            element="vendor1"
                            :list="company.attachmentList"
                            @dropEvent="dropEvent($event, company)"
                            @dropDel="dropDel($event, company)"
                        >
                        </Attachments>
                    </div>

                    <div class="factory">Factory</div>
                    <Tabs
                        :curTab="curTab"
                        :list.sync="tabLen"
                        @change="change"
                        @changeTab="changeTab"
                    ></Tabs>

                    <div v-for="(step, ids) in dataList" :key="ids">
                        <div v-show="curTab == ids">
                            <!-- Factory Information -->
                            <div class="inputList">
                                <div class="company">Factory Information</div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Factory Name
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="input">
                                        <el-input
                                            placeholder=""
                                            v-model="step.factoryName"
                                            @change="
                                                changeName(
                                                    $event,
                                                    ids,
                                                    step.factoryName
                                                )
                                            "
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Factory Address
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="input">
                                        <el-input
                                            placeholder=""
                                            v-model="step.factoryAddress"
                                        ></el-input>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Tel No.
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="input">
                                        <el-input
                                            placeholder=""
                                            v-model="step.telNo"
                                            @change="
                                                changeTest($event, 'telNo')
                                            "
                                        >
                                        </el-input>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Email
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="input">
                                        <el-input
                                            placeholder=""
                                            v-model="step.email"
                                            @change="
                                                changeTest($event, 'email')
                                            "
                                        >
                                        </el-input>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Date Established
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="select">
                                        <el-date-picker
                                            v-model="step.dateEstablished"
                                            type="date"
                                            format="dd/MM/yyyy"
                                            value-format="dd/MM/yyyy"
                                            placeholder="Please select a date"
                                            style="width: 100%"
                                        >
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Ownership Structure
                                    </div>
                                    <div class="select">
                                        <el-checkbox-group
                                            v-model="checkList2[ids].list"
                                            class="mt-1"
                                            @change="
                                                changeCheckboxs($event, step)
                                            "
                                        >
                                            <el-checkbox
                                                :label="item.dictName"
                                                v-for="(
                                                    item, i
                                                ) in ownershipList"
                                                :key="i"
                                            >
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Loading Port Location
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="select flex-start">
                                        <Address
                                            @change="
                                                changeAddress(
                                                    $event,
                                                    'Factory',
                                                    step
                                                )
                                            "
                                        ></Address>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Export Port
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="select">
                                        <el-select
                                            v-model="step.exportPort"
                                            placeholder="Please select"
                                            style="width: 544px"
                                        >
                                            <el-option
                                                v-for="item in exportList"
                                                :key="item.dictName"
                                                :label="item.dictName"
                                                :value="item.dictName"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        CSR
                                        <span class="red-text">*</span>
                                    </div>
                                    <div class="select">
                                        <el-radio-group
                                            v-model="step.csr"
                                            class="mt-1"
                                        >
                                            <el-radio label="Y">Y</el-radio>
                                            <el-radio label="N">N</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                            <!-- Product Scope -->
                            <Scope @change="changeScope($event, step)"></Scope>
                            <!-- Key Market & Customers -->
                            <Market
                                @change="changeMarket($event, step)"
                            ></Market>
                            <!-- Factory Information -->
                            <InfoFile
                                @change="changeInfoFile($event, step)"
                            ></InfoFile>
                            <!-- Business Scale -->
                            <div class="inputList">
                                <div class="company">Business Scale</div>
                                <div class="flex-between mb-2">
                                    <div class="text-normal">
                                        Business Scale
                                    </div>
                                    <div class="table">
                                        <div class="flex-center itemTable">
                                            <div
                                                v-for="(item, t) in yearNmae"
                                                :key="t"
                                            >
                                                {{ item }}
                                            </div>
                                        </div>
                                        <div
                                            class="flex-center itemTable"
                                            v-for="(
                                                item, i
                                            ) in step.businessScaleList"
                                            :key="i"
                                        >
                                            <div>{{ item.aliasName }}</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    v-model="item.thisYear"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    v-model="item.lastYear"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    v-model="item.previousYear"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Attachment  -->
                            <Attachments
                                element="vendor2"
                                :list="step.attachmentList"
                                @dropEvent="dropEvent($event, step)"
                                @dropDel="dropDel($event, step)"
                            ></Attachments>
                        </div>
                    </div>

                    <div class="submit" @click="Submit">Submit</div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Tabs from "../components/Tabs.vue";
import Market from "../components/Market.vue";
import Scope from "../components/Scope.vue";
import Address from "../components/Address.vue";
import InfoFile from "../components/InfoFile.vue";
import Attachments from "../components/Attachments.vue";

import data1 from "../common/data1.js";
import data2 from "../common/data2.js";
export default {
    name: "Vendor",
    components: {
        Attachments,
        Tabs,
        Market,
        Scope,
        InfoFile,
        Address,
    },
    data() {
        return {
            type: 2,
            curTab: 0,
            tabLen: [{ name: "Unnamed 1" }],

            // **********
            dataList: [],
            company: {},
            businessList: [],
            ownershipList: [],
            sexList: [],
            exportList: [],

            // Company Information
            yearNmae: ["", "This Year", "Last Year", "Previous Year"],
            checkList1: [],
            checkList2: [],
            request: false,
            rules: {
                companyName: [
                    {
                        require: true,
                        message: "Company Name can not be empty",
                    },
                ],
                businessAddressCity: [
                    {
                        require: true,
                        message: "Business Address can not be empty",
                    },
                ],
                // businessAddressCountry: [
                //     {
                //         require: true,
                //         message: "Business Address can not be empty",
                //     },
                // ],
                // businessAddressState: [
                //     {
                //         require: true,
                //         message: "Business Address can not be empty",
                //     },
                // ],
                businessLicenseNo: [
                    {
                        require: true,
                        message: "Business License No. can not be empty",
                    },
                ],
                businessType: [
                    {
                        require: true,
                        message: "Business Type can not be empty",
                    },
                ],
                dateEstablished: [
                    {
                        require: true,
                        message: "Date Established can not be empty",
                    },
                ],
                ownershipStructure: [
                    {
                        require: true,
                        message: "Ownership Structure can not be empty",
                    },
                ],
                "contact.contactSex": [
                    {
                        require: true,
                        message: "Contact Person can not be empty",
                    },
                ],
                "contact.contactName": [
                    {
                        require: true,
                        message: "Contact Person can not be empty",
                    },
                ],
                "contact.position": [
                    {
                        require: true,
                        message: "Position can not be empty",
                    },
                ],
                "contact.emailAddress": [
                    {
                        require: true,
                        message: "Email Address can not be empty",
                    },
                ],
                "contact.mobileNumber": [
                    {
                        require: true,
                        message: "Mobile Number can not be empty",
                    },
                ],
            },
            factoryRules: {
                factoryName: [
                    {
                        require: true,
                        message: "Factory Name can not be empty",
                    },
                ],
                factoryAddress: [
                    {
                        require: true,
                        message: "Factory Address can not be empty",
                    },
                ],
                telNo: [
                    {
                        require: true,
                        message: "Tel No. can not be empty",
                    },
                ],
                email: [
                    {
                        require: true,
                        message: "Email can not be empty",
                    },
                ],
                loadingPortLocationCity: [
                    {
                        require: true,
                        message: "Loading Port Location can not be empty",
                    },
                ],
                dateEstablished: [
                    {
                        require: true,
                        message: "Date Established can not be empty",
                    },
                ],
                exportPort: [
                    {
                        require: true,
                        message: "Export Port can not be empty",
                    },
                ],
                csr: [
                    {
                        require: true,
                        message: "CSR can not be empty",
                    },
                ],
            },
        };
    },
    created() {
        data1.businessScaleList[0].aliasName = "USD";
        data1.businessScaleList[1].aliasName = "Volume (no. of TEU’s)";
        this.company = data1;
        this.dataList.push(JSON.parse(JSON.stringify(data2)));
        this.dataList.forEach((os, i) => {
            os.businessScaleList[0].aliasName = "USD";
            os.businessScaleList[1].aliasName = "Volume (no. of TEU’s)";
            os.factoryName = "Unnamed " + (i + 1);
            this.checkList2.push({
                list: [],
            });
        });
        this.businessList = JSON.parse(localStorage.getItem("business_type"));
        this.ownershipList = JSON.parse(
            localStorage.getItem("ownership_structure")
        );
        this.sexList = JSON.parse(localStorage.getItem("sex"));
        this.exportList = JSON.parse(localStorage.getItem("export_port"));
    },

    methods: {
        change(e) {
            this.curTab = e;
        },
        changeName(e, i, str) {
            this.tabLen[i].name = str;
            this.$set(this.tabLen, "name");
        },
        changeTest(e, type) {
            console.log(e, type);
            //   if (type == "email") {
            //     if (!this.$u.email(e)) {
            //       this.$message({
            //         message: "Please Enter The Correct Email Address",
            //         type: "error",
            //       });
            //     }
            //   } else if (type == "telNo") {
            //     if (!this.$u.mobile(e)) {
            //       this.$message({
            //         message: "Please Enter The Correct TelNo",
            //         type: "error",
            //       });
            //     }
            //   }
        },
        changeTab(tabLen, type, e, i = "") {
            this.curTab = e;
            this.tabLen = tabLen;
            if (type == "add") {
                this.dataList.push(JSON.parse(JSON.stringify(data2)));
                this.dataList.forEach((os, i) => {
                    if (e == i) {
                        os.businessScaleList[0].aliasName = "USD";
                        os.businessScaleList[1].aliasName =
                            "Volume (no. of TEU’s)";
                        os.factoryName = "Unnamed " + (i + 1);
                    }
                });
                this.checkList2.push({
                    list: [],
                });
            } else {
                this.dataList.splice(i, 1);
            }
        },
        dropEvent(val, item) {
            item.attachmentList.push(val);
        },
        dropDel(index, item) {
            item.attachmentList.splice(index, 1);
        },
        changeCheckboxs(val, item) {
            item.ownershipStructure = String(val);
        },
        changeScope(val, item) {
            item.productScopeList = val;
        },
        changeMarket(val, item) {
            item.keymarketCustomerList = val;
        },
        changeInfoFile(val, item) {
            item.accreditationList = val;
        },
        changeAddress(e, type, item) {
            console.log(e);
            if (type == "Company") {
                item.businessAddressCity = e.City;
                item.businessAddressCountry = e.Country;
                item.businessAddressState = e.Detail;
            }
            if (type == "Factory" && item) {
                item.loadingPortLocationArea = e.Detail;
                item.loadingPortLocationCity = e.City;
                item.loadingPortLocationCountry = e.Country;
            } else {
                this.dataList.map((os) => {
                    os.loadingPortLocationArea = e.Detail;
                    os.loadingPortLocationCity = e.City;
                    os.loadingPortLocationCountry = e.Country;
                });
            }
        },
        validate(rules, params) {
            const keys = Object.keys(rules);
            console.log(rules);
            for (let i = 0; i < keys.length; i++) {
                const [key, key1, key2] = keys[i].split(".");

                const length = keys[i].split(".").length;

                let result;
                switch (length) {
                    case 1:
                        result = params[key];
                        break;
                    case 2:
                        if (Reflect.has(params, key)) {
                            result = params[key][key1];
                        }
                        break;
                    case 3:
                        if (
                            Reflect.has(params, key) &&
                            Reflect.has(params[key], key1)
                        ) {
                            result = params[key][key1][key2];
                        }
                        break;
                }

                const ruless = rules[keys[i]];

                for (let j = 0; j < ruless.length; j++) {
                    const rule = ruless[j];

                    if (rule.require && !result) {
                        this.$message({
                            message: rule.message,
                            type: "error",
                        });
                        return false;
                    }
                }
            }
            return true;
        },
        Submit() {
            if (this.request) return;
            let params = {
                company: this.company,
                factoryList: this.dataList,
            };
            if (!this.validate(this.rules, this.company)) return;

            for (let i = 0; i < this.dataList.length; i++) {
                if (!this.validate(this.factoryRules, this.dataList[i])) return;
            }
            this.request = true;
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$http
                .submitStep1(params)
                .then((res) => {
                    this.request = false;
                    if (res.code == 200) {
                        this.$message({
                            message: `Thanks for submitting the information. We will review details and contact you via your email address ${this.company.contact.emailAddress} when necessary. `,
                            type: "success",
                        });
                        loading.close();
                        this.$router.push("/");
                    }
                })
                .catch(() => {
                    this.request = false;
                    loading.close();
                });
        },
    },
};
</script>

<style>
.container {
}

.el-header {
    height: 100px;
}

.el-aside {
    height: calc(100vh - 200px);
}

.el-main {
    padding: 0;
    height: calc(100vh - 200px);
}

.header {
    width: 100%;
    height: 140px !important;
    /* background: #2ec9c3; */
    background: #5c5c5c;
    opacity: 1;
    line-height: 100px;
}

.logo {
    height: 140px;
    background: url("../assets/logo.png") 50% 100% no-repeat;
}

.header > div:first-child {
    width: 30%;
    text-align: center;
    line-height: 100px;
}

.header > div:nth-child(n + 1) {
    margin-right: 84px;
}

.menu {
    width: 33.885%;
    padding-right: 75px;
    box-sizing: border-box;
}

.menu > div {
    width: 350px;
    height: 50px;
    line-height: 50px;
    padding-left: 25px;
    box-sizing: border-box;
    font-weight: 400;
}

.menu .title {
    font-size: 18px;
    color: #ffffff;
    border: 1px solid #5c5c5c;
    background: #5c5c5c;
}

.menu .step1,
.menu .step2 {
    font-size: 14px;
    color: #4b5050;
    border: 1px solid #707070;
    border-top: none;
    background: #ffffff;
}

.menu .back {
    background: rgb(92 92 92 / 30%);
}
</style>
